import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/ocard',
    name: 'Ocard',
    component: () => import('@/views/Ocard.vue'),
  },
  {
    path: '/ocard-for-business',
    name: 'Business',
    component: () => import('@/views/Business.vue'),
  },
  {
    path: '/ocard-rewards',
    name: 'Rewards',
    component: () => import('@/views/Rewards.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      top: 0,
    };
  },
});

export default router;
