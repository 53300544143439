<template>
  <section class="navbar">
    <div class="container">
      <div class="row">
        <div class="column column-12 column-xl-10 m-auto">
          <nav class="nav">
            <router-link class="nav-brand" to="/">
              <div class="nav-brand-logo-img">
                <svg width="97" height="25" viewBox="0 0 97 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6.90774 23.1542C4.98539 22.1317 3.46473 20.6831 2.37444 18.8654C1.28416 17.0192 0.739014 14.9743 0.739014 12.7305C0.739014 10.4867 1.28416 8.41329 2.37444 6.56713C3.46473 4.74937 4.98539 3.30085 6.90774 2.27836C8.85878 1.25587 11.0107 0.744629 13.3921 0.744629C15.8022 0.744629 17.9541 1.25587 19.8764 2.27836C21.7988 3.30085 23.3194 4.72097 24.4097 6.53873C25.5 8.35648 26.0451 10.4015 26.0451 12.7021C26.0451 14.9743 25.5 17.0476 24.4097 18.8654C23.3194 20.6831 21.7988 22.1033 19.8764 23.1258C17.9541 24.1482 15.8022 24.6595 13.3921 24.6595C11.0107 24.6879 8.85878 24.1766 6.90774 23.1542ZM17.6385 19.8595C18.9009 19.1494 19.8764 18.1837 20.5937 16.934C21.311 15.6843 21.6553 14.2926 21.6553 12.7305C21.6553 11.1683 21.311 9.77661 20.5937 8.5269C19.8764 7.27719 18.9009 6.31151 17.6385 5.60145C16.376 4.89139 14.9701 4.55056 13.3921 4.55056C11.814 4.55056 10.4081 4.89139 9.14569 5.60145C7.88326 6.31151 6.90774 7.27719 6.19044 8.5269C5.47315 9.77661 5.12885 11.1683 5.12885 12.7305C5.12885 14.2926 5.47315 15.6843 6.19044 16.934C6.90774 18.1837 7.88326 19.1494 9.14569 19.8595C10.4081 20.5695 11.814 20.9104 13.3921 20.9104C14.9701 20.9104 16.376 20.5411 17.6385 19.8595Z" fill="#333333"/>
                  <path d="M32.7015 23.5234C31.2096 22.7565 30.0619 21.6772 29.2298 20.2855C28.3978 18.8938 27.9961 17.3317 27.9961 15.5707C27.9961 13.8098 28.3978 12.2476 29.2298 10.8843C30.0619 9.521 31.2096 8.4417 32.6729 7.64643C34.1361 6.87957 35.8003 6.48193 37.6939 6.48193C39.4728 6.48193 41.0221 6.82276 42.342 7.53282C43.6618 8.24289 44.666 9.26537 45.3546 10.6003L42.1411 12.4748C41.6247 11.6512 40.9935 11.0263 40.2188 10.6287C39.4441 10.2311 38.612 10.0038 37.6939 10.0038C36.1159 10.0038 34.8247 10.5151 33.7918 11.5092C32.7589 12.5317 32.2425 13.8666 32.2425 15.5423C32.2425 17.2181 32.7589 18.5814 33.7631 19.5755C34.796 20.598 36.0872 21.0808 37.6652 21.0808C38.5833 21.0808 39.4154 20.882 40.1901 20.4559C40.9648 20.0583 41.596 19.4334 42.1124 18.6098L45.3259 20.4843C44.6373 21.8193 43.6044 22.8417 42.2846 23.5518C40.9648 24.2619 39.4154 24.6311 37.6652 24.6311C35.8289 24.6879 34.1648 24.2903 32.7015 23.5234Z" fill="#333333"/>
                  <path d="M61.5654 8.38482C62.9713 9.63452 63.6599 11.5375 63.6599 14.0653V24.7162H59.7005V22.3304C59.184 23.0973 58.438 23.6937 57.4912 24.1198C56.5444 24.5174 55.3967 24.7446 54.0482 24.7446C52.6997 24.7446 51.5233 24.5174 50.5191 24.063C49.5149 23.6085 48.7402 22.9837 48.1951 22.16C47.65 21.3647 47.363 20.4274 47.363 19.405C47.363 17.8144 47.9656 16.5363 49.1706 15.5706C50.3757 14.605 52.2693 14.1221 54.8229 14.1221H59.4709V13.8097C59.4709 12.56 59.0979 11.6227 58.352 10.941C57.606 10.2878 56.487 9.94695 54.995 9.94695C53.9908 9.94695 52.9866 10.089 52.0111 10.4014C51.0356 10.7138 50.2035 11.1399 49.5436 11.7079L47.9082 8.69724C48.855 7.98718 49.974 7.44753 51.2938 7.0783C52.6136 6.70907 54.0195 6.51025 55.4828 6.51025C58.1511 6.51025 60.1595 7.13511 61.5654 8.38482ZM57.7207 20.9387C58.5241 20.4559 59.1266 19.7742 59.4422 18.8937V16.8203H55.1098C52.6997 16.8203 51.4946 17.6156 51.4946 19.1777C51.4946 19.9446 51.8103 20.5411 52.4128 20.9671C53.0153 21.4215 53.8474 21.6204 54.9376 21.6204C55.9705 21.6488 56.9174 21.4215 57.7207 20.9387Z" fill="#333333"/>
                  <path d="M76.8008 6.51034V10.4583C76.4565 10.4015 76.1122 10.3447 75.8253 10.3447C74.1899 10.3447 72.9274 11.0547 72.0093 11.992C71.0912 12.9293 70.6321 14.2926 70.6321 16.0536V24.6595H66.4431V6.48193H70.4313V9.521C71.6363 7.67484 73.7595 6.51034 76.8008 6.51034Z" fill="#333333"/>
                  <path d="M96.5121 0.744629V24.6879H92.4666V22.1601C91.778 23.0121 90.9173 23.637 89.8844 24.063C88.8515 24.4891 87.7325 24.6879 86.4987 24.6879C84.7772 24.6879 83.2279 24.3187 81.8507 23.5518C80.4735 22.7849 79.3832 21.734 78.6085 20.3423C77.8338 18.9506 77.4321 17.3601 77.4321 15.5707C77.4321 13.7813 77.8338 12.1908 78.6085 10.8275C79.3832 9.46418 80.4735 8.38489 81.8507 7.64642C83.2279 6.87956 84.7772 6.51032 86.4987 6.51032C87.6751 6.51032 88.7654 6.70914 89.7696 7.10678C90.7451 7.50441 91.6059 8.10086 92.2945 8.89613V0.744629H96.5121ZM89.7696 20.4559C90.5729 20.0015 91.2042 19.3482 91.6919 18.4962C92.151 17.6441 92.4092 16.6784 92.4092 15.5707C92.4092 14.463 92.1797 13.4973 91.6919 12.6453C91.2329 11.7932 90.5729 11.1683 89.7696 10.7139C88.9662 10.2594 88.0481 10.0322 87.0439 10.0322C86.0397 10.0322 85.1215 10.2594 84.3181 10.7139C83.5148 11.1683 82.8836 11.8216 82.3958 12.6453C81.9367 13.4973 81.6785 14.463 81.6785 15.5707C81.6785 16.6784 81.908 17.6441 82.3958 18.4962C82.8549 19.3482 83.5148 20.0015 84.3181 20.4559C85.1215 20.9104 86.0397 21.1376 87.0439 21.1376C88.0481 21.1376 88.9662 20.9104 89.7696 20.4559Z" fill="#333333"/>
                </svg>
              </div>
              <p>品牌資源中心</p>
            </router-link>
            <a href="https://ocard.co/" target="_blank" class="nav-home">
              <img src="../assets/image/Icon/Home.svg" alt="index-link">
            </a>
          </nav>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  watch: {
    $route() {
      const routeName = this.$route.name;
      const navHome = document.querySelector('.nav-home');

      switch (routeName) {
        case 'Ocard':
        case 'Business':
        case 'Rewards':
          navHome.hidden = true;
          break;
        default:
          navHome.hidden = false;
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/all';

.navbar {
  display: none;
  @include media-breakpoint-lg {
    display: block;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;

  .nav-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-brand-logo-img {
      margin-top: 1px;
      margin-right: 24px;
    }

    p {
      position: relative;
      width: 100%;
      font-family: $font-family;
      font-size: 24px;
      font-weight: $font-weight-normal;
      color: $info;
      top: 1px;
      white-space: nowrap;
    }
  }

  .nav-home {
    padding: 18.5px 20px;
    border-radius: 50%;
    background: white;
    box-shadow: none;
    transition: $animation;
    &:hover {
      box-shadow: $shadow;
    }
  }
}
</style>
