<template>
  <Navbar></Navbar>
  <router-view/>
</template>

<script type="module">
import Navbar from '@/components/Navbar.vue';

export default {
  name: 'app',
  components: {
    Navbar,
  },
  mounted() {
    document.title = 'Ocard Brand Resource Center';
  },
};
</script>

<style lang="scss">
  @import '@/assets/scss/all';
</style>
